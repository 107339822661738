import styled from 'styled-components';

export const AccordionContainer = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
`;

export const AccordionItem = styled.div`
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

export const AccordionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  color: #ffffff;
  background-color: #077391;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  ${({ isOpen }) => !isOpen ? `border-bottom-left-radius: 10px;` : `border-bottom-left-radius: none;`}
  ${({ isOpen }) => !isOpen ? `border-bottom-right-radius: 10px;` : `border-bottom-left-radius: 0px;`}
  transition: all 0.3s ease;
`;

export const ChevronIcon = styled.span`
transition: transform 0.3s ease;
  ${({ isOpen }) =>
        isOpen &&
        `
    transform: rotate(90deg);
  `}
`;

export const AccordionContent = styled.div`
padding: 20px;
  ${({ isOpen }) => !isOpen && `display: none;`}
`;