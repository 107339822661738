import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import Accordion from '../components/Accordion/Accordion';
import PageWrapper from '../components/PageWrapper/PageWrapper';
import ContentContainer from '../components/ContentContainer/ContentContainer';
import { Helmet } from 'react-helmet';

const FAQ = () => {
    const accordionItems = [
        {
            title: 'What are admin services, and what tasks do they typically cover',
            content: 'Admin services encompass a broad range of tasks aimed at supporting businesses or individuals with various administrative needs. These tasks often include managing emails, scheduling appointments, data entry, document preparation, and general organizational support.',
        },
        {
            title: 'How can admin services benefit my business or personal needs',
            content: 'We can manage email correspondence, handle calendar scheduling, conduct research, manage data, assist with customer support, handle social media, and perform various other administrative tasks depending on your needs',
        },
        {
            title: 'How do I determine which admin services I need for my business or projects',
            content: 'Assess your current workload and identify tasks that consume significant time but are not directly related to your core business objectives. These tasks are often good candidates for outsourcing to admin services.',
        },
        {
            title: 'What qualifications or expertise should I look for in a virtual assistant or administrative service provider',
            content: 'Look for individuals or agencies with experience in the specific tasks you need assistance with, strong organizational skills, attention to detail, excellent communication, and a track record of reliability.',
        },
        {
            title: 'How do admin service providers ensure data security and confidentiality',
            content: 'We often have confidentiality agreements in place and utilize secure communication channels and data storage methods to ensure the privacy and security of client information.',
        },
        {
            title: 'What is the typical process for hiring or engaging admin services',
            content: 'It usually involves an initial consultation to discuss your needs, followed by agreement on services, terms, and pricing. Then, tasks are assigned as per your requirements.',
        },
        {
            title: 'Are admin services customizable based on specific business requirements',
            content: 'Yes, we tailor our services to meet the unique needs of each client. We often offer flexible packages or customizable plans',
        }
        // Add more items as needed
    ];

    return (
        <>
            <Helmet>
                <title>FAQ - Proworks Office Solutions</title>
                <meta name="description" content="Explore frequently asked questions about our products and services." />
                <meta name="keywords" content="FAQ, frequently asked questions, customer support" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Proworks Office Solutions" />
                {/* Add more meta tags as needed */}
            </Helmet>
            <div>
                <Navbar></Navbar>
                <PageWrapper>
                    <br></br>
                    <ContentContainer>
                        <Accordion items={accordionItems} />
                    </ContentContainer>
                </PageWrapper>
            </div>
        </>
    );
};

export default FAQ;
