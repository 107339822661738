import styled from 'styled-components';

export const LogoContainer = styled.div`
    margin-left: 20px;
    display: block;
    height: auto;
    z-index: 999;
    position: relative;

    @media (max-width: 768px) {
        margin-left: 0px;
        margin-top: 10px;
    }
`;

export const LogoTitle = styled.div`
    font-size: 30px;
    color: #ffffff;
    text-align: left;

    @media (max-width: 768px) {
        text-align: center;
    }
`;

export const LogoSubTitle = styled.div`
    font-size: 10px;
    color: #ffffff;
    text-align: left;
    display: block;

    @media (max-width: 768px) {
        text-align: center;
    }
`;