import React, { useEffect, useState } from 'react';
import Input from '../components/Input/Input';
import Navbar from '../components/Navbar/Navbar';
import PageWrapper from '../components/PageWrapper/PageWrapper';
import SectionHeader from '../components/SectionHeader/SectionHeader';
import Card from '../components/Card/Card';
import ContentContainer from '../components/ContentContainer/ContentContainer';
import Button from '../components/Button/Button';
import styled from 'styled-components';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Helmet } from 'react-helmet';

const StyledCardContainer = styled.div`
        display: grid;
        grid-template-columns: 1fr; /* Default to one column for wider screens */
    
        @media (min-width: 768px) {
            grid-template-columns: 1fr 1fr; /* Switch to two columns for screens 768px and above */
            gap: 20px;
        }
    `;

const ListItem = styled.div`
        display: flex;
        gap: 6px;
        margin-bottom: 5px;
        justify-content: center;
    `;

const NoRecords = styled.div`
        height: 40px;
        width: 100%;
        color: gray;
        display: flex;
        justify-content: center;
        align-items: center;
    `;

const ExpenseCalculator = () => {
    const defaultIncome =
    {
        incomeDescription: "",
        incomeAmount: undefined
    };


    const defaultExpense =
    {
        expenseDescription: "",
        expenseAmount: undefined
    };

    const [income, setIncome] = useState([]);
    const [expense, setExpense] = useState([]);
    const [incomeSubtotal, setIncomeSubtotal] = useState(0);
    const [expenseSubtotal, setExpenseSubtotal] = useState(0);

    const handleAddIncome = () => {
        setIncome([...income, defaultIncome]);
    }

    const handleDeleteIncome = (i) => {
        const deleteVal = [...income];
        deleteVal.splice(i, 1);
        setIncome(deleteVal);
    }

    const handleDeleteExpense = (i) => {
        const deleteVal = [...expense];
        deleteVal.splice(i, 1);
        setExpense(deleteVal);
    }

    const handleAddExpense = () => {
        setExpense([...expense, defaultExpense]);
    }

    const handleInputIncomeChange = (e, i) => {
        const { name, value } = e.target;
        const onChangeVal = [...income];
        onChangeVal[i][name] = value;
        setIncome(onChangeVal);
    };

    const handleInputExpenseChange = (e, i) => {
        const { name, value } = e.target;
        const onChangeVal = [...expense];
        onChangeVal[i][name] = value;
        setExpense(onChangeVal);
    };

    const calculateIncomeSubtotal = () => {
        let subtotal = 0;
        income.forEach(item => {
            const amount = parseFloat(item.incomeAmount);
            if (!isNaN(amount)) {
                subtotal += amount;
            }
        });
        setIncomeSubtotal(subtotal);
    };

    useEffect(() => {
        calculateIncomeSubtotal();
        // eslint-disable-next-line
    }, [income]);

    const calculateExpenseSubtotal = () => {
        let subtotal = 0;
        expense.forEach(item => {
            const amount = parseFloat(item.expenseAmount);
            if (!isNaN(amount)) {
                subtotal += amount;
            }
        });
        setExpenseSubtotal(subtotal);
    };

    useEffect(() => {
        calculateExpenseSubtotal();
        // eslint-disable-next-line
    }, [expense]);

    return (
        <>
            <Helmet>
                <title>Income and Expense Calculator</title>
                <meta name="Income and Expense Calculator" content="This is a simple income and expense calculator that allows you to type in an amount and a description. The calculator allows you to get the subtotsl and net profit or loss." />
            </Helmet>
            <div>
                <Navbar></Navbar>
                <PageWrapper>
                    <ContentContainer variant="wide">
                        <SectionHeader actionButtons={<><Button color="orange" onClick={() => { setIncome([]); setExpense([]); }}>Clear</Button></>}>
                            <span style={{ fontWeight: "bold" }}>Simple Income and Expense Calculator</span>
                        </SectionHeader>
                        <br></br>
                        <StyledCardContainer>
                            <div style={{ marginBottom: "20px", position: "relative" }}>
                                <Card variant={"outline"}>
                                    <Button onClick={handleAddIncome} color='green'>Add Income</Button>
                                    <hr></hr>
                                    {income && income?.length ? income?.map((item, index) => (
                                        <ListItem key={`income-${index}`}>
                                            <Button onClick={() => handleDeleteIncome(index)} key={`income-delete-${index}`} iconOnly color='red'><XMarkIcon width={"20px"} height={"20px"}></XMarkIcon></Button>
                                            <Input
                                                name="incomeDescription"
                                                width={"55%"}
                                                key={`income-description-${index}`}
                                                type='text'
                                                placeholder="Type Description"
                                                value={item.incomeDescription}
                                                onChange={(e) => handleInputIncomeChange(e, index)}
                                            />
                                            <Input
                                                name="incomeAmount"
                                                width={"40%"}
                                                key={`income-value-${index}`}
                                                type='number'
                                                placeholder="Type Amount"
                                                value={item.incomeAmount}
                                                onChange={(e) => handleInputIncomeChange(e, index)}
                                            />
                                        </ListItem>
                                    )) : <NoRecords>Add Record</NoRecords>}
                                    <hr style={{ border: "1px solid black" }}></hr>
                                    <div style={{ display: "flex", fontWeight: "bold", justifyContent: "center" }}>
                                        Subtotal: {incomeSubtotal}
                                    </div>
                                </Card>
                            </div>
                            <div style={{ marginBottom: "20px", position: "relative" }}>
                                <Card variant={"outline"}>
                                    <Button onClick={handleAddExpense} color='green'>Add Expense</Button>
                                    <hr></hr>
                                    {expense && expense?.length ? expense?.map((item, index) => (
                                        <ListItem key={`expense-${index}`}>
                                            <Button onClick={() => handleDeleteExpense(index)} key={`expense-delete-${index}`} iconOnly color='red'><XMarkIcon width={"20px"} height={"20px"}></XMarkIcon></Button>
                                            <Input
                                                name="expenseDescription"
                                                width={"55%"}
                                                key={`expense-description-${index}`}
                                                type='text'
                                                placeholder="Type Description"
                                                value={item.expenseDescription}
                                                onChange={(e) => handleInputExpenseChange(e, index)}
                                            />
                                            <Input
                                                name="expenseAmount"
                                                width={"40%"}
                                                key={`expense-value-${index}`}
                                                type='number'
                                                placeholder="Type Amount"
                                                value={item.expenseAmount}
                                                onChange={(e) => handleInputExpenseChange(e, index)}
                                            />
                                        </ListItem>
                                    )) : <NoRecords>Add Record</NoRecords>}
                                    <hr style={{ border: "1px solid black" }}></hr>
                                    <div style={{ display: "flex", fontWeight: "bold", justifyContent: "center" }}>
                                        Subtotal: {expenseSubtotal}
                                    </div>
                                </Card>
                            </div>
                        </StyledCardContainer>
                        <hr style={{ border: "1px solid black" }}></hr>
                        <div style={{ display: "flex", fontWeight: "bold", justifyContent: "center", color: incomeSubtotal - expenseSubtotal === 0 ? "black" : incomeSubtotal - expenseSubtotal > 0 ? "green" : "red" }}>
                            Net Profit or Loss: {incomeSubtotal - expenseSubtotal}
                        </div>
                    </ContentContainer>

                </PageWrapper>
            </div>
        </>
    );
};

export default ExpenseCalculator;
