import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import Slider from '../components/Slider/Slider';
import Avatar from '../components/Avatar/Avatar';
import ContentContainer from '../components/ContentContainer/ContentContainer';
import Card from '../components/Card/Card';
import PageWrapper from '../components/PageWrapper/PageWrapper';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import styled, { keyframes } from 'styled-components';
import { Helmet } from 'react-helmet';

const Home = () => {
    const animationText = "Welcome to Proworks Office Solutions! Since 2013, we've redefined administrative services with a passion for efficiency and unwavering support. Our mission: empowering organizations by easing their administrative load. We've honed tailored solutions for esteemed clients like the Economic Society of Singapore, amplifying their impact on the economic landscape. Our approach isn't just about tasks; it's about immersing in our clients' operations to fuel their growth. We're more than a support system; we're the driving force propelling businesses toward success. Join us in this journey where efficiency meets excellence, and together, we achieve remarkable milestones.";

    const floatUpDown = keyframes`
    0%, 100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
    `;
    const StyledCircle = styled.div`
        position: relative;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        overflow: hidden;
        height: 100px;
        width: 100px;
        background: #0f0f0f;
        color: #ffffff;
        cursor: pointer;
        animation: ${floatUpDown} 2s ease-in-out infinite;

        &:hover {
            background: #f9b137;
        }
    `;

    const StyledCardHeaderText = styled.div`
        font-size: 20px;
        font-weight: bold;   
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    `;

    const StyledCardBodyText = styled.div`
        font-size: 16px;  
        display: flex;
        align-items: center;
        color: #626262;
    `;

    return (
        <>
            <Helmet>
                <title>Admin Services - Proworks Office Solutions</title>
                <meta name="description" content="Explore our admin services for efficient business operations." />
                <meta name="keywords" content="admin services, business administration, office management" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Proworks Office Solutions" />
            </Helmet>
            <div>
                <Navbar />
                <PageWrapper>
                    <Slider />
                    <ContentContainer>
                        <div id="about" style={{ left: "50%", position: "absolute", marginTop: "-100px", marginLeft: "-50px" }}>
                            <a href="#about" style={{ textDecoration: "none" }}><StyledCircle>
                                <div style={{ position: 'relative', margin: '37px auto', fontWeight: 'bold' }}>About</div>
                            </StyledCircle></a>
                        </div>
                        <div style={{ textShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)", fontFamily: "sans-serif", fontWeight: "bold", fontSize: "55px", marginTop: "30px", color: "#2971a3" }}>OUR COMPANY</div>

                        <div style={{ display: "flex", marginTop: "20px", justifyContent: "center" }}>
                            <Avatar src={"/Founder.jpg"} size={220}></Avatar>
                        </div>
                        <div style={{ display: "flex", marginTop: "20px", justifyContent: "center" }}>
                            <div style={{ maxWidth: "900px", textAlign: "justify", fontSize: "17px", fontStyle: "italic", color: "#626262" }}><div>{animationText}</div></div>
                        </div>
                        <div style={{ display: "flex", marginTop: "20px", justifyContent: "center" }}>
                            <div style={{ maxWidth: "900px", textAlign: "right", fontSize: "20px", fontWeight: "bold" }}><div>- Founder, Vivian Carrasco</div></div>
                        </div>
                        <hr style={{ marginTop: "100px", maxWidth: "900px" }}></hr>
                        <div id="services" style={{ left: "50%", position: "absolute", marginTop: "-50px", marginLeft: "-50px" }}>
                            <a href="#services" style={{ textDecoration: "none" }}><StyledCircle>
                                <div style={{ position: 'relative', margin: '37px auto', fontWeight: 'bold' }}>Services</div>
                            </StyledCircle></a>
                        </div>
                        <div style={{ display: "flex", marginTop: "70px", justifyContent: "center" }}>
                            <div style={{ maxWidth: "900px", textAlign: "left", fontSize: "20px", width: "100%" }}>
                                <Card fadeDirection={"left"}>
                                    <StyledCardHeaderText><CheckCircleIcon style={{ height: "30px", color: "#7dc76e", marginRight: "10px" }}></CheckCircleIcon>Administrative Support</StyledCardHeaderText>
                                    <StyledCardBodyText>
                                        Email management: Sorting, responding, and organizing emails
                                    </StyledCardBodyText>
                                    <StyledCardBodyText>
                                        Calendar management: Scheduling appointments, arranging meetings
                                    </StyledCardBodyText>
                                    <StyledCardBodyText>
                                        Data entry and organization: Managing databases, inputting information
                                    </StyledCardBodyText>
                                    <StyledCardBodyText>
                                        Document preparation: Drafting documents, creating presentations, and reports
                                    </StyledCardBodyText>
                                </Card>
                                <br></br>
                                <Card fadeDirection={"left"}>
                                    <StyledCardHeaderText>
                                        <CheckCircleIcon style={{ height: "30px", color: "#7dc76e", marginRight: "10px" }}></CheckCircleIcon>
                                        Customer Support
                                    </StyledCardHeaderText>
                                    <StyledCardBodyText>
                                        Handling customer inquiries: Responding to emails, live chat support
                                    </StyledCardBodyText>
                                    <StyledCardBodyText>
                                        Managing support tickets: Addressing issues and providing assistance
                                    </StyledCardBodyText>
                                </Card>
                                <br></br>
                                <Card fadeDirection={"left"}>
                                    <StyledCardHeaderText>
                                        <CheckCircleIcon style={{ height: "30px", color: "#7dc76e", marginRight: "10px" }}></CheckCircleIcon>
                                        Communication
                                    </StyledCardHeaderText>
                                    <StyledCardBodyText>
                                        Making and receiving calls: Handling phone calls, taking messages
                                    </StyledCardBodyText>
                                    <StyledCardBodyText>
                                        Managing social media: Scheduling posts, responding to comments/messages
                                    </StyledCardBodyText>
                                </Card>
                                <br></br>
                                <Card fadeDirection={"left"}>
                                    <StyledCardHeaderText>
                                        <CheckCircleIcon style={{ height: "30px", color: "#7dc76e", marginRight: "10px" }}></CheckCircleIcon>
                                        Research
                                    </StyledCardHeaderText>
                                    <StyledCardBodyText>
                                        Internet research: Gathering information, market research, competitor analysis
                                    </StyledCardBodyText>
                                    <StyledCardBodyText>
                                        Data gathering and analysis: Compiling reports, analyzing data sets
                                    </StyledCardBodyText>
                                </Card>
                                <br></br>
                                <Card fadeDirection={"left"}>
                                    <StyledCardHeaderText>
                                        <CheckCircleIcon style={{ height: "30px", color: "#7dc76e", marginRight: "10px" }}></CheckCircleIcon>
                                        Travel Planning
                                    </StyledCardHeaderText>
                                    <StyledCardBodyText>
                                        Booking flights, hotels, and transportation
                                    </StyledCardBodyText>
                                    <StyledCardBodyText>
                                        Creating travel itineraries and managing travel arrangements
                                    </StyledCardBodyText>
                                </Card>
                                <br></br>
                                <Card fadeDirection={"left"}>
                                    <StyledCardHeaderText>
                                        <CheckCircleIcon style={{ height: "30px", color: "#7dc76e", marginRight: "10px" }}></CheckCircleIcon>
                                        Content Creation and Management
                                    </StyledCardHeaderText>
                                    <StyledCardBodyText>
                                        Writing and editing: Blog posts, articles, social media content
                                    </StyledCardBodyText>
                                    <StyledCardBodyText>
                                        Content scheduling and publishing
                                    </StyledCardBodyText>
                                </Card>
                                <br></br>
                                <Card fadeDirection={"left"}>
                                    <StyledCardHeaderText>
                                        <CheckCircleIcon style={{ height: "30px", color: "#7dc76e", marginRight: "10px" }}></CheckCircleIcon>
                                        Financial Support
                                    </StyledCardHeaderText>
                                    <StyledCardBodyText>
                                        Basic bookkeeping tasks: Managing expenses, invoicing
                                    </StyledCardBodyText>
                                </Card>
                            </div>
                        </div>
                        <hr style={{ marginTop: "100px", maxWidth: "900px" }}></hr>
                        <div id="contact" style={{ left: "50%", position: "absolute", marginTop: "-50px", marginLeft: "-50px" }}>
                            <a href="#contact" style={{ textDecoration: "none" }}><StyledCircle>
                                <div style={{ position: 'relative', margin: '33px auto', fontWeight: 'bold' }}>Contact<br></br> Us</div>
                            </StyledCircle></a>
                        </div>
                        <div style={{ display: "flex", marginTop: "70px", justifyContent: "center" }}>
                            <div style={{ maxWidth: "900px", textAlign: "center", fontSize: "20px", width: "100%" }}>
                                <Card>
                                    Connect with us via our live chat at the lower left corner of the screen or email us at <b>vivian.p.tan@gmail.com</b>
                                </Card>
                            </div>
                        </div>
                        <div style={{ marginBottom: "90px" }}></div>
                    </ContentContainer>
                </PageWrapper>
            </div>
        </>
    );
};

export default Home;
