import styled from 'styled-components';

export const StyledInput = styled.input`
padding: 7px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 16px;

    ${({ width }) => width &&
        `width: ${width}`
    }
`;