import React, { useState, useEffect } from 'react';
import { StyledSlider, FadeInText, ImageOverlay } from './SliderStyles';

const Slider = () => {
    // eslint-disable-next-line
    const [currentWord, setCurrentWord] = useState(0);
    const words = [
        'STRATEGIC VISION.',
        'CREATE EXECUTION.',
        'YOUR SUCCESS, OUR MISSION.',
    ]; // Words to loop through
    const colors = {
        0: '#ffffff',
        1: '#96d7ff',
        2: '#ffffff'
    }

    const [fontSize, setFontSize] = useState(16);

    useEffect(() => {
        const handleResize = () => {
            // Calculate font size based on window width
            const width = window.innerWidth;
            const calculatedFontSize = width * 0.06 > 75 ? 75 : width * 0.06;// Adjust min and max font size as needed
            setFontSize(calculatedFontSize);
        };

        // Set initial font size on component mount
        handleResize();

        // Update font size on window resize
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentWord((prevIndex) => (prevIndex + 1) % words.length);
        }, 0); // Change word every 3 seconds

        return () => clearInterval(interval);
    }, [words.length]);

    return (
        <StyledSlider>
            {words.map((word, index) => (
                <FadeInText fontSize={fontSize} color={colors[index]} top={index * 80} key={index} delay={`${index * 0.5}s`} style={{ animationDelay: `${index * 0.5}s` }}>
                    {word}
                </FadeInText>
            ))}
            <ImageOverlay />
        </StyledSlider>
    );
};

export default Slider;
