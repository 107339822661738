import React, { useState } from 'react';
import { AccordionContainer, AccordionItem, AccordionContent, AccordionTitle, ChevronIcon } from './AccordionStyles';
import { ChevronDoubleRightIcon } from '@heroicons/react/20/solid';

const Accordion = ({ items }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <AccordionContainer>
            {items.map((item, index) => (
                <AccordionItem key={index}>
                    <AccordionTitle isOpen={activeIndex === index} onClick={() => toggleAccordion(index)}>
                        <span>{item.title}</span>
                        <ChevronIcon isOpen={activeIndex === index}><ChevronDoubleRightIcon style={{ height: "30px" }}></ChevronDoubleRightIcon></ChevronIcon>
                    </AccordionTitle>
                    <AccordionContent isOpen={activeIndex === index}>
                        {item.content}
                    </AccordionContent>
                </AccordionItem>
            ))}
        </AccordionContainer>
    );
};

export default Accordion;