import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding: 50px;
  display: block;
  text-align: center;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;