import React from 'react';
import { StyledSectionHeader, StyledTitle, StyledButtons } from './SectionHeaderStyles';

const SectionHeader = ({ children, actionButtons }) => {
    return (
        <StyledSectionHeader>
            <StyledTitle>
                {children}
            </StyledTitle>
            <StyledButtons>
                {actionButtons}
            </StyledButtons>
        </StyledSectionHeader>
    );
};

export default SectionHeader;
