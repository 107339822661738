import styled from 'styled-components';

export const NavContainer = styled.nav`
  background-color: #0f0f0f;
  padding: 20px 0px;
  width: 100%;
  height: auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0px;
  z-index: 999;

  @media (max-width: 768px) {
    padding: 0px;
    flex-direction: column;
    align-items: center;
    text-align: center;

    & > * {
      margin-bottom: 10px;
    }
  }
`;

export const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-left: -160px;
  overflow: auto;
  z-index: 1;
  
  @media (max-width: 768px) {
    margin-left: 0px;
    width: 100%;
    display: none;
  }
`;

export const MenuItem = styled.li`
  margin-right: 40px;

  &:last-child {
    margin-right: 0;
  }

  a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    transition: color 0.3s ease;

    &:hover {
      color: #ffcc00;
    }
  }
`;

export const DropdownButton = styled.button`
  display: none;

  @media (max-width: 768px) {
    display: block;
    /* Your dropdown button styles */
  }
`;

export const DropdownMenu = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: ${props => (props.showDropdown ? 'block' : 'none')};
    top: 90px;
    z-index: 999;
    border-radius: 10px;
    position: absolute;
    background: white;
    padding: 15px;
    /* Your dropdown menu styles */
  }
`;

export const DropdownMenuItem = styled.div`
  display: block;
  text-decoration: none;

  > a {
    text-decoration: none;
    color: #0f0f0f;

    &:hover {
      color: #ffcc00;
    }
  }
`;