import styled from 'styled-components';

export const StyledSectionHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: #ffffff;
  background-color: #077391;
  border-radius: 10px;
`;

export const StyledTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  gap: 4px;
`;