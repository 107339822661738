import React from 'react';
import { StyledPageWrapper } from './PageWrapperStyles';

const PageWrapper = ({ children }) => {
    return (
        <StyledPageWrapper>{children}</StyledPageWrapper>
    );
};

export default PageWrapper;
