import React from 'react';
import { LogoTitle, LogoSubTitle, LogoContainer } from './LogoStyles';

const Logo = () => {
    return (
        <LogoContainer>
            <LogoTitle>Proworks</LogoTitle>
            <LogoSubTitle>Office Solutions</LogoSubTitle>
        </LogoContainer>
    );
};

export default Logo;
