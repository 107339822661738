import styled from 'styled-components';

export const StyledAvatarContainer = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Styled component for the avatar image
export const StyledAvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Prevents image stretching */
`;