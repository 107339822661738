import React from 'react';
import { StyledButton } from './ButtonStyles';

const Button = ({ children, color = "blue", iconOnly = false, onClick = () => { } }) => {
    return (
        <StyledButton onClick={onClick} color={color} iconOnly={iconOnly}>{children}</StyledButton>
    );
};

export default Button;
