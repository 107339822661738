import React, { useState, useEffect, useRef } from 'react';
import { NavContainer, MenuList, MenuItem, DropdownButton, DropdownMenu, DropdownMenuItem } from './NavbarStyles';
import { Link } from 'react-router-dom';
import Logo from '../Logo/Logo';

const Navbar = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const menuButtonRef = useRef(null);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleClickAway = (e) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(e.target) &&
            menuButtonRef.current &&
            !menuButtonRef.current.contains(e.target)
        ) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickAway);

        return () => {
            document.removeEventListener('click', handleClickAway);
        };
    }, []);

    return (
        <NavContainer>
            <Link to="/" style={{ textDecoration: "none" }}><Logo></Logo></Link>
            <DropdownButton onClick={toggleDropdown} ref={menuButtonRef}>Menu</DropdownButton>
            <MenuList>
                <MenuItem>
                    <a href="/#about">About us</a>
                </MenuItem>
                <MenuItem>
                    <a href="/#services">Services</a>
                </MenuItem>
                <MenuItem>
                    <a href="tools">Tools</a>
                </MenuItem>
                <MenuItem>
                    <a href="faqs">FAQs</a>
                </MenuItem>
                <MenuItem>
                    <a href="/#contact">Contact Us</a>
                </MenuItem>
            </MenuList>
            <DropdownMenu ref={dropdownRef} showDropdown={showDropdown}>
                {/* Render dropdown items here */}
                <DropdownMenuItem><a href="/#about">About us</a></DropdownMenuItem>
                <DropdownMenuItem><a href="/#services">Services</a></DropdownMenuItem>
                <DropdownMenuItem><a href="tools">Tools</a></DropdownMenuItem>
                <DropdownMenuItem><a href="faqs">FAQs</a></DropdownMenuItem>
                <DropdownMenuItem><a href="/#contact">Contact Us</a></DropdownMenuItem>
            </DropdownMenu>
        </NavContainer>
    );
};

export default Navbar;
