import styled from 'styled-components';

export const StyledButton = styled.button`
  padding: ${props => (props.iconOnly ? '3px 8px' : '10px')};;
  color: #ffffff;
  border-radius: 8px;
  border: none;
  cursor: pointer;

  ${({ color }) => color === "orange" &&
    `background-color: #f99c37;
      color: #5b3109;

      &:hover {
        background-color: #d6852d;
      }
    `
  }

  ${({ color }) => color === "red" &&
    `backgroundr: transparent;
    color: #be3333;
    `
  }

  ${({ color }) => color === "green" &&
    `background-color: #18bb1f;
      color: #ffffff;

      &:hover {
        background-color: #139d19;
      }
    `
  }

  ${({ color }) => color === "blue" &&
    `background-color: #a1d8e7;
      color: #0e3d55;

      &:hover {
        background-color: #8dbecb;
      }
    `
  }
`;