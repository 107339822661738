import React from 'react';
import { StyledContainer } from './ContentContainerStyles';

const ContentContainer = ({ children }) => {
    return (
        <StyledContainer>{children}</StyledContainer>
    );
};

export default ContentContainer;
