import React from 'react';
import { StyledAvatarContainer, StyledAvatarImage } from './AvatarStyles';

const Avatar = ({ src, alt, size }) => {
    return (
        <StyledAvatarContainer size={size}>
            <StyledAvatarImage src={src} alt={alt} />
        </StyledAvatarContainer>
    );
};

export default Avatar;
