import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const StyledSlider = styled.div`
  position: relative;
  width: 100%;
  height: 420px; /* Set height to accommodate the text */
  overflow: hidden; /* Hide overflow for animation */
  background-color: #0f0f0f;
  background-image: url('/office-background.jpg'); /* Replace with your image path */
  background-size: cover; /* Adjust background size */
  background-position: center; /* Adjust background position */
`;

export const FadeInText = styled.span`
  font-family: 'Apercu', sans-serif;
  position: absolute;
  top: ${({ top }) => `${top + 70}px`};
  left: 0;
  width: 100%;
  font-weight: bold;
  font-size: ${({ fontSize }) => `${fontSize}px`};;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5); /* Text shadow properties */
  color: ${({ color }) => color};
  text-align: center;
  animation: ${slideIn} 0.5s ease both; /* Apply animation */
  animation-delay: ${({ delay }) => delay}; /* Add animation delay */
`;

export const ImageOverlay = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px; /* Height of the fading effect at the bottom */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
`;
